<template>
  <div>
    <TableRittenWinstmargeOnderaannemingTemplate />
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'

const TableRittenWinstmargeOnderaannemingTemplate = defineAsyncComponent(() => import('@/components/Table/Ritten/WinstmargeOnderaannemingTemplate.vue'))
</script>
